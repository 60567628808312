<template>
  <div>
    <!-- Basic word entry -->
    <div class="flex">
      <sb-word-entry-form
        ref="entryForm"
        :word-id="$route.params.wordId"
        :with-definitions="false"
        manual-submission
        @manual-submit="handleFormSubmit('entryForm')"
      >
        <i-button @click="handleFormSubmit('entryForm')"> Opslaan </i-button>
      </sb-word-entry-form>
    </div>

    <!-- Word definitions -->
    <div v-if="!!$route.params.wordId && !!word">
      <sb-divider
        :title="`Definities (${get(
          getWordEntryById,
          'definitions',
          'length',
        )})`"
        top-space="0"
      />
      <div>
        <div
          v-for="definition in get(getWordEntryById, 'definitions')"
          :key="definition.id"
          class="sb_dictionary-form"
        >
          <sb-word-definition-form
            :definition-id="definition.id"
            :default-data="
              objectPick(
                definition,
                'image',
                'audio',
                'pos',
                'content',
                'examples',
              )
            "
            :word="word"
            :word-id="$route.params.wordId"
            default-mode="readonly"
            @delete="handleDefinitionDelete"
          />
        </div>

        <p v-if="!getWordEntryById.definitions.length">
          <i>Nog geen definities toegevoegd</i>
        </p>

        <sb-word-definition-form
          v-if="showNewDefinitionForm"
          :word="word"
          default-mode="edit"
          class="row-4"
          @cancel="showNewDefinitionForm = false"
          @created="handleDefinitionCreated"
        />

        <i-button v-else class="row-2" @click="showNewDefinitionForm = true">
          Definitie toevoegen
        </i-button>
      </div>
    </div>

    <div v-if="!!$route.params.wordId && !!word">
      <sb-divider
        :title="`Relaties (${get(getWordEntryById, 'relations', 'length')})`"
        top-space="4rem"
      />

      <sb-word-relations-form
        ref="form"
        :lemma-word="word"
        :word-id="$route.params.wordId"
        :default-data="{ create: getWordEntryById.relations }"
      />
    </div>
  </div>
</template>

<script>
import SbWordEntryForm from './SbWordEntryForm.vue';
import SbWordRelationsForm from './SbWordRelationsForm.vue';
import SbWordDefinitionForm from './SbWordDefinitionForm.vue';
import GetWordEntryById from './GetWordEntryById.gql';
import { objectPick } from '@/lib/object-pick';

export default {
  name: 'SbDictionaryForm',
  components: { SbWordEntryForm, SbWordRelationsForm, SbWordDefinitionForm },
  props: {},

  data() {
    return {
      step: 0,
      stepsAmount: 999,
      showNewDefinitionForm: false,
    };
  },

  computed: {
    word() {
      return this.getWordEntryById?.word;
    },
  },

  methods: {
    objectPick,

    async handleDefinitionDelete(id) {
      this.$apollo.queries.getWordEntryById.refetch();
      this.getWordEntryById.definitions.slice(
        this.getWordEntryById.definitions.findIndex((e) => e.id === id),
        1,
      );
    },

    handleDefinitionCreated(data) {
      this.showNewDefinitionForm = false;
      this.$apollo.queries.getWordEntryById.refetch();
      this.getWordEntryById.definitions.push(data);
    },

    handleFormSubmit(form) {
      this.$refs[form]
        .submit()
        .then((result) => {
          if (!result) return;
          this.handleNextResult(result);
        })
        .catch((error) => {
          console.error(error);
          this.$showGenericError();
        });
    },

    handleNextResult(result) {
      if (!result?.data) return;

      const { data } = result;

      if (!this.$route.params.wordId) {
        this.$router.push({
          name: this.$route.name.split('Dictionary')[0] + 'DictionaryEdit',
          params: {
            wordId: data.createWordEntry?.id || data.updateWordEntry?.id,
          },
        });
      }
    },
  },

  apollo: {
    getWordEntryById: {
      skip() {
        return !this.$route.params.wordId;
      },

      variables() {
        return { id: this.$route.params.wordId };
      },

      update(data) {
        if (!data.getWordEntryById.definitions?.length) {
          this.showNewDefinitionForm = true;
        }
        return data.getWordEntryById;
      },

      query: GetWordEntryById,
    },
  },
};
</script>

<style scoped lang="scss">
.sb_dictionary-form {
  padding: 2rem;
  &:nth-child(even) {
    background: $brand-primary-lightest;
  }

  &:first-of-type {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &:last-of-type {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
</style>

<script>
import { onLogout } from '@/vue-apollo';
import Dictionary from './Dictionary.vue';
export default {
  components: { SbDictionary: Dictionary },

  methods: {
    async logout() {
      await onLogout(this.$apollo.provider.defaultClient);
      location.reload();
    },
  },
};
</script>

<template>
  <div class="sb-dictionary-maintainer">
    <i-button @click="logout"> Uitloggen </i-button>
    <sb-dictionary />
  </div>
</template>

<style scoped lang="scss">
.sb-dictionary-maintainer {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}
</style>

<template>
  <div class="sb_dictionary">
    <sb-page-header>
      <template v-slot:left>
        <h1>Woordenboek</h1>
      </template>
      <template v-slot:right>
        <div class="flex gap-2">
          <i-button
            type="primary"
            @click="
              $router.push({
                name: $route.name.split('Dictionary')[0] + 'DictionaryAdd',
              })
            "
          >
            Woord toevoegen
          </i-button>

          <dropdown placement="bottom-end">
            <i-button type="primary" ghost>
              Acties
              <icon type="md-more" />
            </i-button>

            <dropdown-menu slot="list">
              <router-link
                :to="{
                  name: $route.name.split('Dictionary')[0] + 'DictionaryAdd',
                }"
              >
                <sb-dropdown-button> Woord toevoegen </sb-dropdown-button>
              </router-link>

              <router-link
                v-if="get($user, 'role') === 'ADMIN'"
                :to="{ name: 'ResourceManagementDictionaryMaintainers' }"
              >
                <sb-dropdown-button> Beheerder aanmaken </sb-dropdown-button>
              </router-link>
              <div @click="doShowHelpDrawer = true">
                <sb-dropdown-button> Hulp bij definiëren </sb-dropdown-button>
              </div>
            </dropdown-menu>
          </dropdown>
        </div>
      </template>
    </sb-page-header>

    <sb-gql-table
      ref="table"
      :config="tableConfig"
      :query-options="queryOptions"
      data-path="wordEntries"
      @cell-click-word="goToEdit"
      @row-action-edit="goToEdit"
    ></sb-gql-table>

    <sb-drawer
      class="sb_dictionary_drawer"
      :show="doShowHelpDrawer"
      @close="doShowHelpDrawer = false"
    >
      <div class="row-in-2">
        <h2 class="row-2">Hulp bij definiëren</h2>
        <p>
          Gebruik dit hulpmiddel om een lijst in te genereren van alle mogelijke
          woorden van alle kaart-slides-inhoud, waar nog geen woordenboek-inhoud
          van bestaat.
        </p>
        <sb-call-out>
          <template #left>⚠️</template>
          <template #right>
            Het ophalen van deze data is zwaar voor de server, dus probeer deze
            feature verantwoordelijk te gebruiken; bij voorkeur buiten typische
            gebruikstijden van het platform!
          </template>
        </sb-call-out>
      </div>

      <div class="row-2 flex flex-col gap-2">
        <checkbox v-model="didReadWarning"
          >Ik heb de waarschuwing gelezen</checkbox
        >
        <i-button
          type="warning"
          :disabled="!didReadWarning || !!undefinedWords.length"
          @click="fetchCardsAndWordEntries"
        >
          Data ophalen
        </i-button>
      </div>

      <template v-if="get(undefinedWords, 'nonExistent', 'length')">
        <div class="row-2 flex flex-align-center flex-space-between">
          <span>
            {{ undefinedWords.nonExistent.length }} missende woorden.
          </span>
          <i-button class="row-2" @click="copyNonExistentWords">
            Kopiëren
          </i-button>
        </div>

        <div class="row-2">
          <i-input
            type="textarea"
            :value="undefinedWords.nonExistent.join('\n')"
            :autosize="{ minRows: 5, maxRows: 12 }"
            readonly
          />
        </div>
      </template>

      <template v-if="get(undefinedWords, 'noDefinitions', 'length')">
        <div class="row-2 flex flex-align-center flex-space-between">
          <span>
            {{ undefinedWords.noDefinitions.length }} woorden zonder
            definitie(s).
          </span>
          <i-button class="row-2" @click="copyWordsWithoutDefinitions">
            Kopiëren
          </i-button>
        </div>

        <div class="row-2">
          <i-input
            type="textarea"
            :value="undefinedWords.noDefinitions.join('\n')"
            :autosize="{ minRows: 5, maxRows: 12 }"
            readonly
          />
        </div>
      </template>
    </sb-drawer>

    <sb-spacer height="200" />

    <sb-loading v-if="loadingCards" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import gql from 'graphql-tag';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from '@/components/SbTable2/TableConfig';
import { StringFilter } from '@/lib/gql-filters';
import SbGqlTable from '@/components/SbTable2/SbGqlTable.vue';
import { wordRelationsMixin, WordRelations } from '@/lib/word-relations';
import SbDrawer from '@/components/SbDrawer.vue';
import { fetchAllCardsMixin } from '@/mixins/fetchAllCardsMixin';
import SbCallOut from '@/components/SbCallOut.vue';
import SbLoading from '@/components/SbLoading.vue';
import { objectPick } from '@/lib/object-pick';
import { GraphQL } from '@/lib/graphql';
import { BooleanFilter } from '@/lib/gql-filters';

export default {
  name: 'OnlineStudents',
  components: {
    SbPageHeader,
    SbGqlTable,
    SbDrawer,
    SbCallOut,
    SbLoading,
  },

  mixins: [
    wordRelationsMixin,
    fetchAllCardsMixin((self) => ({
      onStart: () => (self.loadingCards = true),
      onCards: (cards) => (self.cards = cards),
      onEnd: () => (self.loadingCards = false),
      onError: (error) => (self.loadingCards = false),
      getFragment: (fragment) =>
        [
          fragment,
          `
        slides {
          content {
            ... on CardSlideContentType1 { title text }
            ... on CardSlideContentType3 { title sentences }
            ... on CardSlideContentType4 { table }
            ... on CardSlideContentType5 { table }
            ... on CardSlideContentType6 { words { text } }
            ... on CardSlideContentType7 { words { text } }
            ... on CardSlideContentType8 { title items { textLeft textRight } }
          }
        }
      `,
        ].join('\n'),
    })),
    nonReactiveMembersMixin(() => ({
      tableConfig: new TableConfig({
        rowActions: [['edit', 'Bewerken']],
        columns: [
          {
            key: 'word',
            header: 'Woord',
            action: true,
            filter: 'search',
            sortable: true,
            sort: 'ascending',
            width: 200,
            meta: { gqlFilter: StringFilter },
          },
          {
            key: 'hasDefinition',
            header: 'Definitie(s)',
            filter: 'boolean-optional',
            checkedOptional: null,
            data: (row) => (row.definitions?.length ? '✅ Ja' : '❌ Nee'),
          },
          {
            key: 'stem',
            header: 'Stam',
            data: ({ relations }) =>
              relations
                .filter((r) => r.relation === WordRelations.STEM)
                .map((r) => r.match)
                .join(', '),
          },
          {
            key: 'plural',
            header: 'Meervoud',
            data: ({ relations }) =>
              relations
                .filter((r) => r.relation === WordRelations.PLURAL)
                .map((r) => r.match)
                .join(', '),
          },
          {
            key: 'diminutive',
            header: 'Verklnwd. enkelvoud',
            data: ({ relations }) =>
              relations
                .filter((r) => r.relation === WordRelations.DIMINUTIVE)
                .map((r) => r.match)
                .join(', '),
          },
          {
            key: 'diminutivePlural',
            header: 'Verklnwd. meervoud',
            data: ({ relations }) =>
              relations
                .filter((r) => r.relation === WordRelations.DIMINUTIVEPLURAL)
                .map((r) => r.match)
                .join(', '),
          },
          {
            key: 'conjugation',
            header: 'Vervoeging',
            data: ({ relations }) =>
              relations
                .filter((r) => r.relation === WordRelations.CONJUGATION)
                .map((r) => r.match)
                .join(', '),
          },
        ],
      }),
    })),
  ],

  props: {},

  data: () => ({
    doShowHelpDrawer: false,
    loadingCards: false,
    cards: undefined,
    wordEntries: undefined,
    didReadWarning: false,
  }),

  computed: {
    undefinedWords() {
      function getObjectValues(obj) {
        return Object.values(obj).map((value) => {
          if (!value) return null;
          if (typeof value === 'string') return value;
          if (typeof value === 'object') return getObjectValues(value);
          return null;
        });
      }

      const slideContent =
        this.cards
          ?.map((card) => card.slides)
          .flat()
          .map((slide) => slide.content)
          .map((content) =>
            objectPick(
              content,
              'title',
              'text',
              'sentences',
              'table',
              'words',
              'items',
            ),
          ) ?? [];

      const rawContent = slideContent
        .map((content) => getObjectValues(content))
        .flat(3);

      const div = document.createElement('div');
      const textContent = rawContent
        .map((textContent) => {
          div.innerHTML = textContent;
          return div.innerText
            .trim()
            .replaceAll(/[^a-zA-Z]/g, ' ')
            .split(' ')
            .filter(Boolean);
        })
        .flat()
        .filter((text) =>
          [!!text, !text.startsWith('CardSlideContent')].every(Boolean),
        )
        .sort();

      const words = Array.from(new Set(textContent));

      const undefinedWords = words.reduce(
        (acc, word) => {
          const entry = this.wordEntries?.find((entry) => entry.word === word);

          if (!entry) {
            acc.nonExistent.push(word);
            return acc;
          }

          if (entry.definitions.length === 0) {
            acc.noDefinitions.push(word);
          }

          return acc;
        },
        { nonExistent: [], noDefinitions: [] },
      );

      return undefinedWords;
    },
  },

  methods: {
    copyNonExistentWords() {
      if (!this.undefinedWords?.nonExistent) return;
      navigator.clipboard.writeText(this.undefinedWords.nonExistent.join('\n'));
      this.$Message.success('Woorden gekopieerd naar klembord');
    },

    copyWordsWithoutDefinitions() {
      if (!this.undefinedWords?.noDefinitions) return;
      navigator.clipboard.writeText(
        this.undefinedWords.noDefinitions.join('\n'),
      );
      this.$Message.success('Woorden gekopieerd naar klembord');
    },

    fetchCardsAndWordEntries() {
      this.fetchAllCards();
      this.$apollo
        .query({
          variables: { first: GraphQL.MAX_SAFE_INTEGER },
          query: gql`
            query Dictionary_HelpTool_WordEntries($first: Int) {
              wordEntries(first: $first) {
                edges {
                  node {
                    id
                    word
                    definitions {
                      id
                    }
                  }
                }
              }
            }
          `,
        })
        .then(
          (result) =>
            (this.wordEntries = result.data.wordEntries.edges.map(
              (edge) => edge.node,
            )),
        )
        .catch(() => this.$showGenericError());
    },

    goToEdit(row) {
      const goToRouteName =
        this.$route.name.split('Dictionary')[0] + 'DictionaryEdit';

      this.$router.push({
        name: goToRouteName,
        params: { wordId: row.id },
      });
    },

    queryOptions({ first, last, after, before, filter, filters, orderBy }) {
      const query = gql`
        query DictionaryWordEntries(
          $first: Int
          $last: Int
          $after: String
          $before: String
          $orderBy: WordEntriesOrderBy
          $filter: WordEntriesFilter
        ) {
          wordEntries(
            first: $first
            last: $last
            after: $after
            before: $before
            orderBy: $orderBy
            filter: $filter
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            totalCount
            edges {
              node {
                id
                word
                definitions {
                  id
                }
                relations {
                  wordId
                  relation
                  match
                }
              }
            }
          }
        }
      `;

      const variables = {
        first,
        last,
        after,
        before,
        orderBy: orderBy.word ? orderBy : undefined,
        filter: {
          ...filter,
          hasDefinition: filters?.hasDefinition?.checkedOptional ?? undefined,
        },
      };

      if (!query) throw new Error('Unable to determine query');

      return {
        query,
        variables,
      };
    },
  },
};
</script>

<style lang="scss"></style>

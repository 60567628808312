var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex"},[_c('sb-word-entry-form',{ref:"entryForm",attrs:{"word-id":_vm.$route.params.wordId,"with-definitions":false,"manual-submission":""},on:{"manual-submit":function($event){return _vm.handleFormSubmit('entryForm')}}},[_c('i-button',{on:{"click":function($event){return _vm.handleFormSubmit('entryForm')}}},[_vm._v(" Opslaan ")])],1)],1),(!!_vm.$route.params.wordId && !!_vm.word)?_c('div',[_c('sb-divider',{attrs:{"title":("Definities (" + (_vm.get(
        _vm.getWordEntryById,
        'definitions',
        'length'
      )) + ")"),"top-space":"0"}}),_c('div',[_vm._l((_vm.get(_vm.getWordEntryById, 'definitions')),function(definition){return _c('div',{key:definition.id,staticClass:"sb_dictionary-form"},[_c('sb-word-definition-form',{attrs:{"definition-id":definition.id,"default-data":_vm.objectPick(
              definition,
              'image',
              'audio',
              'pos',
              'content',
              'examples'
            ),"word":_vm.word,"word-id":_vm.$route.params.wordId,"default-mode":"readonly"},on:{"delete":_vm.handleDefinitionDelete}})],1)}),(!_vm.getWordEntryById.definitions.length)?_c('p',[_c('i',[_vm._v("Nog geen definities toegevoegd")])]):_vm._e(),(_vm.showNewDefinitionForm)?_c('sb-word-definition-form',{staticClass:"row-4",attrs:{"word":_vm.word,"default-mode":"edit"},on:{"cancel":function($event){_vm.showNewDefinitionForm = false},"created":_vm.handleDefinitionCreated}}):_c('i-button',{staticClass:"row-2",on:{"click":function($event){_vm.showNewDefinitionForm = true}}},[_vm._v(" Definitie toevoegen ")])],2)],1):_vm._e(),(!!_vm.$route.params.wordId && !!_vm.word)?_c('div',[_c('sb-divider',{attrs:{"title":("Relaties (" + (_vm.get(_vm.getWordEntryById, 'relations', 'length')) + ")"),"top-space":"4rem"}}),_c('sb-word-relations-form',{ref:"form",attrs:{"lemma-word":_vm.word,"word-id":_vm.$route.params.wordId,"default-data":{ create: _vm.getWordEntryById.relations }}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
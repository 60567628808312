<template>
  <i-select
    placeholder="relatie"
    clearable
    :value="value"
    @input="$emit('input', $event)"
  >
    <i-option
      v-for="relationType in options"
      :key="relationType"
      :value="relationType"
    >
      {{ getWordRelationsLabel(relationType) }}
    </i-option>
  </i-select>
</template>

<script>
import { wordRelationsMixin } from '@/lib/word-relations';

export default {
  mixins: [wordRelationsMixin],

  props: {
    value: { type: String, default: undefined },
    omit: { type: Array, default: undefined },
  },

  computed: {
    options() {
      return Object.values(this.WordRelations).filter((e) =>
        this.omit ? !this.omit.includes(e) : e,
      );
    },
  },
};
</script>

<style lang="scss">
.sb_word-relation-select {
}
</style>

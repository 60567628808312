import gql from 'graphql-tag';
import { noop } from 'lodash';

export const fetchAllCardsMixin = (settings) => ({
  methods: {
    async fetchAllCards(current = [], endCursor) {
      const {
        onStart = noop,
        onCards = noop,
        onEnd = noop,
        onError = noop,
        onProgress = noop,
        getFragment = () => CARD_FRAGMENT,
      } = settings(this);

      onStart();

      try {
        const { data, errors } = await this.$apollo.query({
          variables: { first: 100, after: endCursor },
          query: gql`
            query CardsList_Cards($first: Int, $after: String) {
              cards(first: $first, after: $after ) {
                totalCount
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                  startCursor
                  endCursor
                }
                edges {
                  node {
                    ${getFragment(CARD_FRAGMENT)}
                    ${this.withLinkedProblems ? LINKED_PROBLEMS_FRAGMENT : ''}
                  }
                }
              }
            }
          `,
        });

        if (errors) {
          onCards(current);
          onEnd();
          return this.$showGenericError(
            undefined,
            'Niet alle kaarten konden worden opgehaald.',
          );
        }

        onProgress(data.cards, current);

        if (data.cards.pageInfo.hasNextPage) {
          return void this.fetchAllCards(
            current.slice().concat(data.cards.edges.map(({ node }) => node)),
            data.cards.pageInfo.endCursor,
          );
        }

        current = current.concat(data.cards.edges.map(({ node }) => node));
        onCards(current);
        onEnd();
      } catch (error) {
        onError(error);
        console.error(error);
      }
    },
  },
});

export const CARD_FRAGMENT = `
id
title
number
thumbnail
type
level
methodologies
addReadingComprehension
readingComprehension { questions { question } }
`;

export const LINKED_PROBLEMS_FRAGMENT = `
linkedProblems {
  id
  title
  description
  types
}
`;

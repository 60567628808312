<script>
import Dictionary from './DictionaryAdd.vue';
export default {
  components: { SbDictionary: Dictionary },
};
</script>

<template>
  <div class="sb-dictionary-maintainer">
    <i-button @click="$router.push({ name: 'SystemMaintainerDictionary' })">
      Terug naar overzicht
    </i-button>
    <sb-dictionary />
  </div>
</template>

<style scoped lang="scss">
.sb-dictionary-maintainer {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}
</style>

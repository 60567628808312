<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Woord toevoegen</h1>
      </template>
    </sb-page-header>

    <sb-dictionary-form default-mode="edit" />

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbDictionaryForm from '@/components/SbDictionaryForm/SbDictionaryForm';

export default {
  components: {
    SbPageHeader,
    SbDictionaryForm,
  },

  props: {},

  computed: {},
};
</script>

<style lang="scss"></style>

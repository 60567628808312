<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Woordenboek beheer</h1>
      </template>
    </sb-page-header>

    <sb-call-out style="max-width: 800px">
      <template #left>ℹ️</template>
      <template #right>
        <p>Je kan hier een woordenboek-beheerder account aanmaken.</p>
        <p>
          Een woordenboek-beheerder heeft slechts toegang tot het woordenboek,
          en gerelateerde features om het woordenboek te beheren. Dit betreft
          het inzien en wijzigen van woorden, definities en relaties.
        </p>
      </template>
    </sb-call-out>

    <div style="position: relative">
      <sb-loading v-if="isLoading" />

      <template v-if="!isCreated">
        <sb-divider title="Woordenboek-beheerder aanmaken" />

        <i-form
          ref="userForm"
          :model="formData"
          class="row-4"
          autocomplete="off"
        >
          <input
            autocomplete="false"
            name="hidden"
            type="text"
            style="display: none"
          />

          <form-item
            label="Voornaam"
            prop="firstName"
            :rules="{
              required: true,
              message: 'Voer een voornaam in',
              trigger: 'blur',
            }"
          >
            <i-input v-model="formData.firstName" autocomplete="off" />
          </form-item>

          <form-item label="Tussenvoegsel" prop="prefix">
            <i-input v-model="formData.prefix" autocomplete="off" />
          </form-item>

          <form-item
            label="Achternaam"
            prop="lastName"
            :rules="{
              required: true,
              message: 'Voer een achternaam in',
              trigger: 'blur',
            }"
          >
            <i-input v-model="formData.lastName" autocomplete="off" />
          </form-item>

          <form-item
            label="E-mailadres"
            prop="email"
            :rules="{
              required: true,
              message: 'Voer een e-mailadres in',
              trigger: 'blur',
            }"
          >
            <i-input
              v-model="formData.email"
              type="email"
              name="dictionary-manager-email"
              autocomplete="off"
            />
          </form-item>

          <form-item
            label="Wachtwoord"
            prop="password"
            :rules="{
              trigger: 'blur',
              validator: passwordValidator,
            }"
          >
            <i-input
              v-model="formData.password"
              type="password"
              autocomplete="new-password"
            />
          </form-item>

          <form-item
            label="Wachtwoord bevestigen"
            prop="passwordConfirm"
            :rules="{
              trigger: 'blur',
              validator: passwordConfirmValidator,
            }"
          >
            <i-input
              v-model="formData.passwordConfirm"
              type="password"
              autocomplete="new-password"
            />
          </form-item>

          <div
            class="flex flex-justify-end"
            style="max-width: 600px; padding: 0 1.5rem"
          >
            <i-button type="primary" @click="handleSubmit">Opslaan</i-button>
          </div>
        </i-form></template
      >

      <div v-if="isCreated" class="row-4">
        <p>Nieuwe woordenboek-beheerder aangemaakt!</p>
        <p>
          De beheerder kan inloggen met het ingevoerde e-mailadres en
          wachtwoord:
        </p>

        <div class="sb_dictionary-maintainers_credentials">
          <div class="flex flex-align-center gap-3">
            <div class="sb_dictionary-maintainers_credentials_label">
              Gebruikersnaam
            </div>
            <pre>{{ username }}</pre>
          </div>
        </div>
        <div class="sb_dictionary-maintainers_credentials">
          <div class="flex flex-align-center gap-3">
            <div class="sb_dictionary-maintainers_credentials_label">
              Wachtwoord
            </div>
            <pre v-if="doShowCredentials">{{ formData.password }}</pre>
            <i-button @click="doShowCredentials = !doShowCredentials">
              {{ doShowCredentials ? 'verbergen' : 'wachtwoord weergeven' }}
            </i-button>
          </div>
        </div>
      </div>
    </div>

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbCallOut from '@/components/SbCallOut.vue';
import SbDivider from '@/components/SbDivider.vue';
import gql from 'graphql-tag';
import { objectOmit } from '@/lib/object-omit';
import SbLoading from '@/components/SbLoading.vue';

export default {
  components: {
    SbPageHeader,
    SbCallOut,
    SbDivider,
    SbLoading,
  },

  props: {},

  data() {
    return {
      isLoading: false,
      isCreated: false,
      doShowCredentials: false,
      username: undefined,
      formData: {
        firstName: undefined,
        prefix: undefined,
        lastName: undefined,
        email: undefined,
        password: undefined,
        passwordConfirm: undefined,
      },
    };
  },

  beforeRouteLeave(to, from, next) {
    if (!this.isCreated) return next();

    const doLeave = confirm(
      'Weet je zeker dat je de pagina wil verlaten? Je kan de logingegevens dan niet meer inzien.',
    );

    return next(doLeave);
  },

  methods: {
    async handleSubmit() {
      const valid = await this.$refs.userForm.validate();

      if (!valid) return;

      this.isLoading = true;

      try {
        const createResult = await this.$apollo.mutate({
          variables: { input: objectOmit(this.formData, 'passwordConfirm') },
          mutation: gql`
            mutation DictionaryMaintainers_CreateUser(
              $input: CreateUserInput!
            ) {
              createUser(input: $input) {
                id
                username
              }
            }
          `,
        });

        const userId = createResult.data.createUser.id;
        this.username = createResult.data.createUser.username;

        const systemUserResult = await this.$apollo.mutate({
          variables: { input: { id: userId, role: 'DICTIONARY_MAINTAINER' } },
          mutation: gql`
            mutation DictionaryMaintainers_SetOperatorRole(
              $input: SetSystemOperatorRoleInput!
            ) {
              setSystemOperatorRole(input: $input)
            }
          `,
        });

        if (!systemUserResult.data.setSystemOperatorRole) {
          this.removeUser(userId);
          throw new Error('Could not set system operator role');
        }

        this.isCreated = true;
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      } finally {
        this.isLoading = false;
      }
    },

    removeUser(userId) {
      console.log('remove user', userId);
    },

    passwordValidator(_, value, callback) {
      if (!value) {
        return callback(new Error('Voer een wachtwoord in'));
      }

      if (value.length < 8) {
        return callback(
          new Error('Voer een wachtwoord in van minstens 8 tekens.'),
        );
      }

      return callback();
    },

    passwordConfirmValidator(_, value, callback) {
      if (this.formData.password !== this.formData.passwordConfirm) {
        return callback(new Error('De wachtwoorden komen niet overeen.'));
      }

      return this.passwordValidator(_, value, callback);
    },
  },
};
</script>

<style lang="scss">
.sb_dictionary-maintainers_credentials {
  background: $brand-lightest-gray;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;

  &_label {
    width: rem-calc(120);
    user-select: none;
  }

  pre {
    margin: 0.5rem 0;
  }
}
</style>

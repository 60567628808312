<template>
  <div>
    <i-select
      ref="entrySelect"
      placeholder="Zoek woord"
      filterable
      clearable
      :value="value"
      v-bind="$attrs"
      @input="handleSelectInput"
      @on-query-change="handleQueryChange"
      @click.native="handleSelectClick"
    >
      <i-option
        :value="'_search_' + searchTerm"
        @mousedown.native="newEntryAutoFill = searchTerm"
        @click.native="handleNewEntryClick"
      >
        <div class="flex flex-align-center gap-1">
          <sb-icon icon-id="icon_plus" />
          <b> {{ creationText }}</b>
        </div>
      </i-option>

      <i-option v-for="option in wordOptions" :key="option" :value="option">
        {{ option }}
      </i-option>
    </i-select>

    <sb-drawer
      :show="doShowNewRelationDrawer"
      @close="doShowNewRelationDrawer = false"
    >
      <sb-word-entry-form
        v-if="doShowNewRelationDrawer"
        :lemma-word="lemmaWord"
        :relation-type="relationType"
        :default-value="newEntryAutoFill"
        with-submit-button
        @created="handleNewEntryCreated($event, true)"
      />

      <button
        class="row-4"
        type="button"
        @click="doShowNewRelationDrawer = false"
      >
        <sb-button button-type="primary" size="large">
          <sb-icon icon-id="icon_arrow-left" /> klaar
        </sb-button>
      </button>
    </sb-drawer>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import SbWordEntryForm from './SbWordEntryForm.vue';
import SbDrawer from '../SbDrawer.vue';

export default {
  components: {
    SbWordEntryForm,
    SbDrawer,
  },

  props: {
    creationText: { type: String, default: 'woord aanmaken' },
    lemmaWord: { type: String, default: undefined },
    relationType: { type: String, default: undefined },
    value: { type: String, default: undefined },
    defaultWordOptions: { type: Array, default: () => [] },
  },

  data() {
    return {
      newEntryAutoFill: '',
      searchTerm: '',
      wordOptions: [],
      doShowNewRelationDrawer: false,
    };
  },

  watch: {
    defaultWordOptions: {
      handler(value) {
        this.wordOptions = Array.from(
          new Set([...this.wordOptions, ...value]),
        ).filter(Boolean);
      },
      immediate: true,
    },
  },

  methods: {
    handleSelectInput(value) {
      const skipEmit =
        value === this.searchTerm && !this.wordOptions.includes(value);
      if (skipEmit) return;
      this.$emit('input', value);
    },

    handleQueryChange(value) {
      this.searchTerm = value;
    },

    handleSelectClick() {
      this.searchTerm = '';
    },

    handleNewEntryClick() {
      this.$refs.entrySelect.setQuery('');
      this.$refs.entrySelect.clearSingleSelect();
      this.$emit('input', undefined);
      this.doShowNewRelationDrawer = true;
    },

    handleNewEntryCreated(event, drawerState = false) {
      this.$refs.entrySelect.setQuery(event.word);
      this.$emit('input', event.word);
      if (!drawerState) this.$el.click(); // lose select focus
      this.doShowNewRelationDrawer = drawerState;
    },
  },

  apollo: {
    getWordOptions: {
      fetchPolicy: 'network-only',
      debounce: 300,
      variables() {
        return {
          first: 10,
          filter: {
            word: {
              mode: 'INSENSITIVE',
              contains: this.searchTerm,
            },
          },
        };
      },
      update(data) {
        this.wordOptions = Array.from(
          new Set([
            ...this.wordOptions,
            ...data.wordEntries.edges.map(({ node }) => node.word),
          ]),
        ).filter(Boolean);
      },
      query: gql`
        query DictionaryFormRelationsInput_WordEntries(
          $first: Int
          $filter: WordEntriesFilter
        ) {
          wordEntries(first: $first, filter: $filter) {
            edges {
              node {
                id
                word
              }
            }
          }
        }
      `,
    },
  },
};
</script>

<template>
  <i-form
    v-if="mode === 'edit'"
    ref="form"
    class="sb_word-definition-input"
    :model="formData"
    :label-width="140"
    v-bind="$attrs"
  >
    <form-item label="Afbeelding">
      <sb-info>
        <p>
          Voeg een afbeelding toe die relevant is voor deze definitie van het
          woord.
        </p>
        <p><i>jpeg of png, maximaal 5MB</i></p>
      </sb-info>
      <upload
        :action="uploadUrl"
        :default-file-list="getDefaultFileList('image')"
        :headers="{ Authorization }"
        :max-size="1024 * 5"
        :on-remove="() => (formData.image = undefined)"
        :on-success="(event) => (formData.image = event)"
        accept=".jpg, .jpeg, .png"
        type="drag"
      >
        Klik, of sleep een afbeelding
      </upload>
    </form-item>

    <form-item label="Audio">
      <sb-info>
        <p>Voeg een audiobestand toe met de uitspraak van het woord.</p>
        <p><i>mpeg, maximaal 5MB</i></p>
      </sb-info>
      <upload
        :action="uploadUrl"
        :default-file-list="getDefaultFileList('audio')"
        :headers="{ Authorization }"
        :max-size="1024 * 5"
        :on-remove="() => (formData.audio = undefined)"
        :on-success="(event) => (formData.audio = event)"
        accept="audio/mpeg"
        type="drag"
      >
        Klik, of sleep een audiobestand
      </upload>
    </form-item>

    <form-item
      label="Woordsoort"
      prop="pos"
      :rules="{
        required: true,
        message: 'Kies een woordsoort',
        trigger: 'blur',
      }"
    >
      <sb-info>
        <p>
          Bijvoorbeeld:
          <i>
            Werkwoord, zelfstandig naamwoord, bijvoeglijk naamwoord,
            voornaamwoord, bijwoord, lidwoord, telwoord, voegwoord, voorzetsel
            of tussenwerpsel.
          </i>
        </p>
      </sb-info>
      <i-select v-model="formData.pos" clearable>
        <i-option v-for="option in DEFAULT_POS" :key="option" :value="option">
          {{ option }}
        </i-option>
      </i-select>
    </form-item>

    <form-item
      label="Betekenis"
      prop="content"
      :rules="{
        required: true,
        message: 'Vul een betekenis in',
        trigger: 'blur',
      }"
    >
      <sb-info>
        <p>De betekenis van woord dat je wil definiëren.</p>
        <p>
          <i> "Plat, groen onderdeel van planten dat groeit aan een tak" </i>
        </p>
      </sb-info>
      <i-input
        v-model="formData.content"
        type="textarea"
        placeholder="betekenis"
      />
    </form-item>

    <form-item label="Voorbeeldzinnen" prop="examples">
      <sb-info>
        <p>
          Één of meerdere voorbeeldzinnen waar het woord in context wordt
          gebruikt.
        </p>
        <p>
          <i>
            "Mag ik nog een blaadje van je, ik heb niks meer om op te
            schrijven."
          </i>
        </p>
      </sb-info>

      <div class="sb_dictionary-form_array-entry-grid">
        <template v-for="(_, exampleIndex) of formData.examples">
          <i-input
            :key="`example-${exampleIndex}`"
            v-model="formData.examples[exampleIndex]"
            placeholder="Voorbeeldzin"
          />

          <button
            :key="`example-${exampleIndex}-remove`"
            type="button"
            @click="formData.examples.splice(exampleIndex, 1)"
          >
            <sb-button button-type="light-black" size="small" round ghost>
              <sb-icon icon-id="icon_minus" />
            </sb-button>
          </button>
        </template>

        <button type="button" @click="formData.examples.push('')">
          <sb-button size="small" button-type="light">
            <sb-icon icon-id="icon_plus" />
            Voorbeeldzin
          </sb-button>
        </button>
      </div>
    </form-item>

    <div class="flex flex-justify-end gap-2">
      <i-button
        @click="
          resetFormData();
          mode = 'readonly';
          $emit('cancel');
        "
      >
        {{ cancelText }}
      </i-button>
      <i-button @click="submit">
        {{ submitText }}
      </i-button>
    </div>
  </i-form>

  <div v-else>
    <p style="font-size: 1.1rem">
      {{ word }}<span v-if="formData.pos">:</span>
      <i v-if="formData.pos">{{ ` ${formData.pos}` }}</i>
    </p>

    <img
      v-if="formData.image"
      :src="formData.image"
      class="inline-block sb_word-definition-form_image"
    />

    <p>
      {{ formData.content }}
    </p>

    <audio
      v-if="formData.audio"
      :src="formData.audio"
      controls
      class="block row-2"
    />

    <p v-for="(example, index) in formData.examples" :key="index">
      "{{ example }}"
    </p>

    <div v-if="!!definitionId" class="flex flex-justify-end gap-2">
      <i-button type="error" @click="remove">
        {{ deleteText }}
      </i-button>
      <i-button @click="mode = 'edit'">{{ editText }}</i-button>
    </div>
  </div>
</template>

<script>
import { vmConfirm } from '@/lib/vm-confirm';
import { formDataMixin } from '@/mixins/formDataMixin';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import gql from 'graphql-tag';
import CreateWordDefinition from './CreateWordDefinition.gql';
import UpdateWordDefinition from './UpdateWordDefinition.gql';

const DEFAULT_POS = [
  'bijvoeglijk naamwoord',
  'bijwoord',
  'lidwoord',
  'telwoord',
  'tussenwerpsel',
  'voegwoord',
  'voornaamwoord',
  'voorzetsel',
  'werkwoord',
  'zelfstandig naamwoord',
];

export default {
  mixins: [
    nonReactiveMembersMixin(() => ({
      DEFAULT_POS,
      uploadUrl: String(process.env.VUE_APP_GRAPHQL_HTTP).replace(
        'graphql',
        'upload',
      ),
      Authorization: `Bearer ${localStorage.getItem('apollo-token')}`,
    })),
    formDataMixin({
      pos: undefined,
      content: undefined,
      examples: [],
      image: undefined,
      audio: undefined,
    }),
  ],

  props: {
    word: { type: String, required: true },
    definitionId: { type: String, default: undefined },
    cancelText: { type: String, default: 'Annuleren' },
    submitText: { type: String, default: 'Opslaan' },
    editText: { type: String, default: 'Bewerken' },
    deleteText: { type: String, default: 'Verwijderen' },
    defaultMode: {
      type: String,
      default: 'readonly',
      validator: (value) => ['readonly', 'edit'].includes(value),
    },
  },

  data() {
    return { mode: this.defaultMode };
  },

  methods: {
    async submit() {
      const valid = await this.$refs.form.validate();
      if (!valid) return;

      const result = await (!!this.definitionId
        ? this.update()
        : this.create());

      this.mode = 'readonly';
      this.$emit(
        'submit',
        result.data[
          !!this.definitionId ? 'updateWordDefinition' : 'createWordDefinition'
        ],
      );
    },

    async update() {
      try {
        const result = await this.$apollo.mutate({
          mutation: UpdateWordDefinition,
          variables: {
            input: {
              id: this.definitionId,
              data: this.formData,
            },
          },
        });

        this.$Message.success('Definitie gewijzigd');
        this.$emit('updated', result.data);
        return result;
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },

    async create() {
      try {
        const result = await this.$apollo.mutate({
          mutation: CreateWordDefinition,
          variables: { input: { ...this.formData, word: this.word } },
        });

        this.$Message.success('Definitie opgeslagen');
        this.$emit('created', result.data);
        return result;
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },

    async remove() {
      const doDelete = await vmConfirm.call(
        this,
        'Weet je het zeker?',
        'Weet je zeker dat je deze definitie wil verwijderen?',
      );

      if (!doDelete) return;

      try {
        const result = await this.$apollo.mutate({
          variables: { id: this.definitionId },
          mutation: gql`
            mutation WordDefinitionForm_Delete($id: ID!) {
              deleteWordDefinition(id: $id)
            }
          `,
        });

        this.$Message.success('Definitie verwijderd');
        this.$emit('delete', this.definitionId);
        return result;
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },

    /**
     * @param {"audio" | "image"} type
     */
    getDefaultFileList(type) {
      return this.formData[type]
        ? [
            {
              url: this.formData[type],
              name: this.formData[type]
                .split('/')
                .reverse()[0]
                .split('-')
                .slice(1)
                .join(''),
            },
          ]
        : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_dictionary-form_array-entry-grid {
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 1rem;

  button {
    & > * {
      width: 100%;
      justify-content: space-between;
    }
  }
}

.sb_word-definition-form_image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 0.5rem;
}
</style>

<template>
  <i-form ref="form" class="row-4" :label-width="80">
    <form-item label="Huidig" style="max-width: 800px">
      <sb-info>
        <p>Gerelateerde woorden van "{{ lemmaWord }}".</p>
      </sb-info>
      <div class="sb_word-relations-form_current">
        <template
          v-for="(relation, index) in get(getWordEntryById, 'relations')"
        >
          <router-link
            :key="['match', index, relation.wordId, relation.match].join('-')"
            :to="{
              name: $route.name.split('Dictionary')[0] + 'DictionaryEdit',
              params: {
                wordId: get(findWordByMatch(relation.match), 'id'),
              },
            }"
          >
            <b>
              {{ relation.match }}
            </b>
          </router-link>
          <i
            v-if="get(editing, 'match') !== relation.match"
            :key="
              ['relation', index, relation.wordId, relation.match].join('-')
            "
          >
            {{ getWordRelationsLabel(relation.relation) }}
          </i>

          <sb-word-relation-select
            v-else
            :key="
              ['edit-select', index, relation.wordId, relation.match].join('-')
            "
            v-model="editing.relation"
            style="width: 200px"
          />

          <template v-if="get(editing, 'match') !== relation.match">
            <button
              :key="['edit', index, relation.wordId, relation.match].join('-')"
              type="button"
              @click="toggleEdit(relation)"
            >
              <sb-button button-type="light-black" size="small" round ghost>
                <sb-icon icon-id="icon_pencil" />
              </sb-button>
            </button>

            <button
              :key="
                ['remove', index, relation.wordId, relation.match].join('-')
              "
              type="button"
              @click="remove(relation, true)"
            >
              <sb-button button-type="light-black" size="small" round ghost>
                <sb-icon icon-id="icon_minus" />
              </sb-button>
            </button>
          </template>

          <template v-else>
            <i-button
              :key="
                ['edit-save', index, relation.wordId, relation.match].join('-')
              "
              type="primary"
              @click="saveEdit"
            >
              Opslaan
            </i-button>
            <i-button
              :key="
                ['edit-cancel', index, relation.wordId, relation.match].join(
                  '-',
                )
              "
              @click="toggleEdit"
            >
              Annuleren
            </i-button>
          </template>
        </template>
      </div>
    </form-item>

    <form-item
      label="Nieuw"
      prop="relations"
      :rules="{}"
      style="max-width: 800px"
    >
      <sb-info>
        <p>Definieer woorden die gerelateerd zijn aan "{{ lemmaWord }}".</p>
        <p>Bijvoorbeeld <i>meervoud, enkelvoud</i> of <i>vervoeging</i></p>
      </sb-info>

      <div
        v-for="(entry, index) in entries"
        :key="index"
        class="sb_word-relations-form_entry"
      >
        <div class="flex flex-col gap-2">
          <div class="flex flex-align-center gap-2">
            <b>
              {{ lemmaWord }}
            </b>
            is
            <sb-word-relation-select
              v-model="entry.lemmaType"
              style="flex: 1"
            />
            van
            <sb-word-entry-select
              v-model="entry.relationWord"
              style="flex: 1"
            />
          </div>

          <div
            v-if="!!entry.relationWord && !!entry.lemmaType"
            class="flex flex-align-center gap-2"
          >
            <b>{{ entry.relationWord }}</b> is
            <sb-word-relation-select
              v-model="entry.relationType"
              style="width: 200px"
              :omit="[entry.lemmaType]"
            />
            van
            <b>{{ lemmaWord }}</b>
          </div>
        </div>

        <button type="button" @click="entries.splice(index, 1)">
          <sb-button button-type="light-black" size="small" round ghost>
            <sb-icon icon-id="icon_minus" />
          </sb-button>
        </button>
      </div>

      <section class="flex flex-space-between">
        <button
          type="button"
          @click="
            entries.push({
              relationWord: undefined,
              lemmaType: undefined,
              relationType: undefined,
            })
          "
        >
          <sb-button size="small" button-type="light">
            <sb-icon icon-id="icon_plus" />
            Relatie
          </sb-button>
        </button>

        <button v-if="!!entries.length" type="button" @click="create">
          <sb-button
            size="small"
            :disabled="!enableSubmitButton"
            button-type="primary"
          >
            Opslaan
          </sb-button>
        </button>
      </section>
    </form-item>
  </i-form>
</template>

<script>
import { wordRelationsMixin } from '@/lib/word-relations';
import SbWordEntrySelect from './SbWordEntrySelect.vue';
import SbWordRelationSelect from './SbWordRelationSelect.vue';
import { changeLoggerMixin } from '@/mixins/changeLogger';
import GetWordEntryById from './GetWordEntryById.gql';
import UpdateWordEntry from './UpdateWordEntry.gql';
import gql from 'graphql-tag';

export default {
  components: { SbWordEntrySelect, SbWordRelationSelect },

  mixins: [wordRelationsMixin, changeLoggerMixin(['entries'])],

  props: {
    wordId: { type: String, required: true },
  },

  data() {
    return { editing: undefined, entries: [] };
  },

  computed: {
    enableSubmitButton() {
      return (
        !!this.entries.length &&
        !!this.snapshot.lemmaCreate.length &&
        !!this.snapshot.relationCreate.length
      );
    },

    lemmaWord() {
      return this.getWordEntryById?.word;
    },

    snapshot() {
      const entries = this.entries;
      const lemmaWord = this.lemmaWord;
      if (!lemmaWord) return;

      const filter = (e) => !!e.match && !!e.relation;

      const lemmaCreate = entries
        .map((e) => ({
          match: e.relationWord,
          relation: e.relationType,
        }))
        .filter(filter);

      const relationCreate = entries
        .map((e) => ({
          match: lemmaWord,
          relation: e.lemmaType,
        }))
        .filter(filter);

      return { lemmaCreate, relationCreate };
    },
  },

  methods: {
    findWordByMatch(match) {
      return this.relatedWords?.find((e) => e.word === match);
    },

    async saveEdit() {
      if (!this.editing) return;
      try {
        await this.remove(this.editing, false);
        await this.$apollo.mutate({
          variables: {
            input: {
              id: this.wordId,
              data: {
                relations: {
                  create: [
                    {
                      match: this.editing.match,
                      relation: this.editing.relation,
                    },
                  ],
                },
              },
            },
          },
          mutation: UpdateWordEntry,
        });

        this.toggleEdit();
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      } finally {
        this.$apollo.queries.getWordEntryById.refetch();
      }
    },

    toggleEdit(relation) {
      if (this.editing) return (this.editing = undefined);
      this.editing = relation;
    },

    async remove(relation, doRefetch) {
      try {
        await this.$apollo.mutate({
          variables: {
            input: {
              id: relation.wordId,
              data: { relations: { delete: [relation.match] } },
            },
          },
          mutation: gql`
            mutation WordRelationsForm_Remove($input: UpdateWordEntryInput!) {
              updateWordEntry(input: $input) {
                id
              }
            }
          `,
        });
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      } finally {
        if (doRefetch) this.$apollo.queries.getWordEntryById.refetch();
      }
    },

    async create() {
      const { lemmaCreate, relationCreate } = this.snapshot;
      const isValid = [
        !!lemmaCreate.length,
        !!relationCreate.length,
        lemmaCreate.length === relationCreate.length,
      ].every(Boolean);

      if (!isValid) return;
      if (!this.lemmaWord) return;

      try {
        // add relations to the lemma word
        this.$apollo.mutate({
          variables: {
            input: {
              id: this.wordId,
              data: { relations: { create: lemmaCreate } },
            },
          },
          mutation: UpdateWordEntry,
        });

        // add the lemma word as relation of the lemma relations

        /** Find the word ids for entry inputs */
        const relationWordEntries =
          (
            await this.$apollo.query({
              variables: {
                filter: {
                  word: {
                    in: lemmaCreate.map((e) => e.match),
                  },
                },
              },
              query: gql`
              query WordRelationsForm_FindRelationEntries($filter: WordEntriesFilter) {
                wordEntries(filter: $filter, first: ${lemmaCreate.length}) {
                  edges { node { id word } }
                }
              }
            `,
            })
          )?.data?.wordEntries?.edges?.map((e) => e.node) ?? [];

        // create GQL mutation params for each word entry: $data_aap, $data_noot, $data_mies
        const mutationParams = relationWordEntries
          .map((node) => `$data_${node.word}: UpdateWordEntryDataInput!`)
          .join(', ');

        // create GQL mutation fragments: updateWordEntry(input: "012345", data: $data_aap)
        const fragments = relationWordEntries.map((node) => {
          const alias = [node.id, node.word].join('_');
          const data = `$data_${node.word}`;

          return `
            ${alias}: updateWordEntry(input: { id: "${node.id}", data: ${data} }) {
              id word relations { match relation }
            }
          `;
        });

        // create mutation variables for each $data_x mutation param
        const variables = relationCreate.reduce((acc, value, index) => {
          const relationWord = lemmaCreate[index].match;
          acc[`data_${relationWord}`] = { relations: { create: value } };
          return acc;
        }, {});

        // merge all operations into a single call
        await this.$apollo.mutate({
          variables,
          mutation: gql`
            mutation WordRelationsForm_CreateRelations(${mutationParams}) { ${fragments} }
          `,
        });

        this.entries = [];
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      } finally {
        this.$apollo.queries.getWordEntryById.refetch();
      }
    },
  },

  apollo: {
    getWordEntryById: {
      skip() {
        return !this.wordId;
      },

      variables() {
        return { id: this.wordId };
      },

      update(data) {
        this.wordEntryOptions = data.getWordEntryById.relations.map(
          (r) => r.match,
        );

        return data.getWordEntryById;
      },

      query: GetWordEntryById,
    },

    relatedWords: {
      skip() {
        return !this.getWordEntryById?.relations?.length;
      },

      variables() {
        return {
          filter: {
            word: {
              in: this.getWordEntryById?.relations.map((r) => r.match),
            },
          },
        };
      },

      update(data) {
        return data.wordEntries.edges
          .filter(
            ({ node }) =>
              !!this.getWordEntryById.relations.find(
                (r) => r.match === node.word,
              ) &&
              node.relations.find(
                (r) => r.match === this.getWordEntryById.word,
              ),
          )
          .map((e) => e.node);
      },

      query: gql`
        query WordRelationsForm_RelatedWords(
          $first: Int
          $filter: WordEntriesFilter
        ) {
          wordEntries(first: $first, filter: $filter) {
            edges {
              node {
                id
                word
                relations {
                  match
                }
                definitions {
                  id
                  pos
                  audio
                  image
                  content
                  examples
                }
              }
            }
          }
        }
      `,
    },
  },
};
</script>

<style lang="scss">
::v-deep {
  .ivu-form-item {
    max-width: 800px !important;
  }
}

.sb_word-relations-form_current {
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.sb_word-relations-form_entry {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;

  &:last-of-type {
    margin-bottom: 1rem;
  }

  &:nth-child(even) {
    background: $brand-primary-lightest;
  }
}
</style>

<template>
  <div>
    <i-form
      ref="form"
      class="row-4"
      :model="formData"
      :label-width="80"
      v-bind="$attrs"
      @submit.native.prevent="
        manualSubmission ? $emit('manual-submit', self) : submit()
      "
    >
      <form-item
        :label="label"
        prop="word"
        :rules="{
          required: true,
          trigger: 'blur',
          validator,
        }"
      >
        <sb-info>
          <p>Het woord dat je wil definiëren.</p>
          <p>Bijvoorbeeld <i>"blad", "blaadje" of "bladeren"</i></p>
        </sb-info>
        <i-input
          v-model="formData.word"
          :placeholder="label"
          required
          :disabled="!!createdEntry"
        >
          <slot v-if="!createdEntry" slot="append">
            <i-button @click="submit"> Opslaan </i-button>
          </slot>
        </i-input>
      </form-item>
    </i-form>

    <div
      v-if="withDefinitions && createdEntry"
      class="sb_word-entry-form_definitions"
    >
      <sb-divider
        :title="`Definities (${
          get(getWordEntryById, 'definitions', 'length') || 0
        })`"
        top-space="0"
      />

      <div>
        <div
          v-for="definition in get(getWordEntryById, 'definitions')"
          :key="definition.id"
          class="sb_dictionary-form"
        >
          <sb-word-definition-form
            :definition-id="definition.id"
            :default-data="
              objectPick(
                definition,
                'image',
                'audio',
                'pos',
                'content',
                'examples',
              )
            "
            :word="createdEntry.word"
            :word-id="createdEntry.id"
            default-mode="readonly"
            @delete="handleDefinitionDelete"
          />
        </div>

        <p
          v-if="
            !!getWordEntryById &&
            !get(getWordEntryById, 'definitions', 'length')
          "
        >
          <i>Nog geen definities toegevoegd</i>
        </p>

        <sb-word-definition-form
          v-if="showNewDefinitionForm"
          :word="createdEntry.word"
          default-mode="edit"
          class="row-4"
          @cancel="showNewDefinitionForm = false"
          @created="handleDefinitionCreated"
        />

        <i-button v-else class="row-2" @click="showNewDefinitionForm = true">
          Definitie toevoegen
        </i-button>
      </div>
    </div>
  </div>
</template>

<script>
import { wordRelationsMixin } from '@/lib/word-relations';
import GetWordEntryById from './GetWordEntryById.gql';
import gql from 'graphql-tag';
import SbWordDefinitionForm from './SbWordDefinitionForm.vue';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { objectPick } from '@/lib/object-pick';

export default {
  components: { SbWordDefinitionForm },
  mixins: [wordRelationsMixin, nonReactiveMembersMixin((self) => ({ self }))],

  props: {
    wordId: { type: String, default: undefined },
    lemmaWord: { type: String, default: undefined },
    defaultValue: { type: String, default: undefined },
    relationType: { type: String, default: undefined },
    submitText: { type: String, default: 'Opslaan' },
    manualSubmission: { type: Boolean, default: false },
    withDefinitions: { type: Boolean, default: true },
  },

  data() {
    return {
      editMode: !!this.wordId,
      formData: { word: this.defaultValue },
      showNewDefinitionForm: false,
      createdEntry: undefined,
    };
  },

  computed: {
    label() {
      return (
        [this.relationType, this.lemmaWord].filter(Boolean).join(' van ') ||
        'woord'
      );
    },
  },

  methods: {
    objectPick,

    validator(_, value, callback) {
      const specialChars = /[ `‘!@#$%^&*()_+\=\[\]{};:"\\|,.<>\/?~]/;

      if (!value) return callback(new Error('Voer een woord in.'));

      if (value.match(specialChars)) {
        return callback(new Error('Gebruik geen speciale tekens'));
      }

      return callback();
    },

    async submit(doEmit = true) {
      const valid = await this.$refs.form.validate();

      if (valid && !this.editMode) {
        const result = await this.createWordEntry();
        if (!result) return;
        this.createdEntry = result.data.createWordEntry;
        if (doEmit) this.$emit('created', result.data.createWordEntry);
        return result;
      }

      if (valid && this.editMode) {
        const result = await this.updateWordEntry();
        if (doEmit) this.$emit('updated', result.data.updateWordEntry);
        return result;
      }
    },

    async updateWordEntry() {
      try {
        const withRelation = !!this.relationType && !!this.lemmaWord;
        const result = await this.$apollo.mutate({
          variables: {
            input: {
              id: this.wordId,
              data: {
                word: this.formData.word,
                relations: withRelation
                  ? {
                      create: [
                        {
                          word: this.lemmaWord,
                          relation: this.type,
                        },
                      ],
                    }
                  : undefined,
              },
            },
          },
          mutation: gql`
            mutation WordEntryForm_Update($input: UpdateWordEntryInput!) {
              updateWordEntry(input: $input) {
                id
                word
              }
            }
          `,
        });
        this.$Message.success('Woord aangepast');
        return result;
      } catch (error) {
        console.error(error.message);
        this.$showGenericError();
      }
    },

    async createWordEntry() {
      try {
        const withRelation = !!this.relationType && !!this.lemmaWord;
        const result = await this.$apollo.mutate({
          variables: {
            input: {
              word: this.formData.word,
              relations: withRelation
                ? {
                    create: [
                      {
                        word: this.lemmaWord,
                        relation: this.type,
                      },
                    ],
                  }
                : undefined,
            },
          },
          mutation: gql`
            mutation WordEntryForm_Create($input: CreateWordEntryInput!) {
              createWordEntry(input: $input) {
                id
                word
              }
            }
          `,
        });
        this.$Message.success('Woord aangemaakt');
        return result;
      } catch (error) {
        this.$showGenericError(
          'Oeps!',
          error?.message?.includes('exists')
            ? 'Dit woord bestaat al.'
            : undefined,
        );
      }
    },

    async handleDefinitionDelete(id) {
      this.$apollo.queries.getWordEntryById.refetch();
      this.getWordEntryById.definitions.slice(
        this.getWordEntryById.definitions.findIndex((e) => e.id === id),
        1,
      );
    },

    handleDefinitionCreated(data) {
      this.showNewDefinitionForm = false;
      this.$apollo.queries.getWordEntryById.refetch();
      this.getWordEntryById.definitions.push(data);
    },
  },

  apollo: {
    getWordEntryById: {
      skip() {
        console.log('skip?', this.createdEntry);
        return !this.wordId && !this.createdEntry?.id;
      },
      variables() {
        return { id: this.wordId || this.createdEntry?.id };
      },
      update(data) {
        this.formData.word = data.getWordEntryById.word;
        return data.getWordEntryById;
      },
      query: GetWordEntryById,
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_word-entry-form_definitions {
  ::v-deep {
    .ivu-form-item {
      margin-bottom: 1.5rem !important;
    }
  }
}
</style>

<template>
  <div
    class="sb_call-out"
    :type="type"
  >
    <div class="sb_call-out_left">
      <slot name="left"></slot>
    </div>
    <div class="sb_call-out_right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbCallOut',
  props: {
    /**
     * The type of the call out, possible values: 'default', 'white', 'lightest-gray', 'red', 'green'
     */
    type: {
      default: 'default',
      validator: value => [
        'default',
        'white',
        'lightest-gray',
        'red',
        'green'
      ].includes(value)
    }
  }
};
</script>

<style lang="scss"
>
  .sb_call-out {
    display: flex;
    border-radius: 10px;
    padding: 25px;

    ul {
      margin: 10px 0 0 20px;
    }

    &_left {
      flex: 0 0 50px;
      font-size: 28px;
      line-height: 1;
    }
    &_right {


    }

    &[type="default"] {
      background: $brand-lightest-gray;
      border: solid 1px $brand-light-gray;
    }
    &[type="white"] {
      background: $brand-white;
      border: solid 1px $brand-light-gray;
    }
    &[type="lightest-gray"] {
      background: $brand-lightest-gray;
      border: solid 1px $brand-light-gray;
    }
    &[type="red"] {
      background: rgba($brand-vibe-red, .08);
      border: solid 1px$brand-vibe-red;
    }
    &[type="green"] {
      background: rgba($brand-vibe-green, .08);
      border: solid 1px$brand-vibe-green;
    }
  }
</style>

<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Woord aanpassen</h1>
      </template>
      <template v-slot:right>
        <i-button
          type="primary"
          @click="
            $router.push({
              name: $route.name.split('Dictionary')[0] + 'DictionaryAdd',
            })
          "
        >
          Nog een woord toevoegen
        </i-button>
      </template>
    </sb-page-header>

    <sb-dictionary-form />

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbDictionaryForm from '@/components/SbDictionaryForm/SbDictionaryForm';
import gql from 'graphql-tag';

export default {
  components: {
    SbPageHeader,
    SbDictionaryForm,
  },
};
</script>

<style lang="scss"></style>

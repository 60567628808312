/**
 * Provides reactive form data. The mixin adds `defaultData` and `validators` props for default data and form validation respectively. A `formData` state object is inserted into `data`.
 * @param {Object} extendsFormData data to extend
 * @param {String} refName (optional) form ref name
 * @returns form data mixin
 * @example
 * export default {
 *   mixins: [
 *     // inject mixin
 *     formDataMixin({
 *       // predefine properties
 *       email: undefined,
 *       username: undefined,
 *       age: undefined
 *     })
 *   ]
 * }
 */
export function formDataMixin(extendsFormData = {}) {
  return {
    props: {
      defaultData: {
        type: Object,
        required: false,
      },

      validators: {
        type: Object,
        required: false,
      },
    },

    data() {
      const formData = {
        ..._.cloneDeep(extendsFormData),
        ..._.cloneDeep(this.defaultData),
      };

      return { formData };
    },

    created() {
      this.initialFormData = Object.freeze(
        JSON.parse(
          JSON.stringify({
            ...extendsFormData,
            ...this.$options.propsData.defaultData,
          }),
        ),
      );
    },

    mounted() {
      this.unwatchDefaultData = this.$watch(
        'defaultData',
        (value, previousValue) => {
          if (!previousValue && value) {
            const formData = {
              ...extendsFormData,
              ...this.defaultData,
              ...value,
            };

            this.unwatchDefaultData?.();
            this.formData = formData;
            this.initialFormData = Object.freeze(
              JSON.parse(JSON.stringify(formData)),
            );
          }
        },
        { immediate: true },
      );
    },

    watch: {
      formData: {
        handler(formData) {
          this.$emit('input', _.cloneDeep(formData));
        },
        deep: true,
      },
    },

    methods: {
      resetFormData() {
        Object.entries(this.initialFormData).forEach(([key, value]) => {
          this.formData[key] = value;
        });
      },
    },
  };
}
